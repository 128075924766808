define("ember-modal-dialog/templates/components/tether-dialog", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.hasOverlay}}
    <EmberWormhole @to={{this.destinationElementId}}>
      <EmberModalDialog::Overlay
        class={{this.overlayClassNamesString}}
        @onClickOverlay={{this.onClickOverlay}}
      />
    </EmberWormhole>
  {{/if}}
  <EmberTether
    class={{this.containerClassNamesString}}
    @target={{this.tetherTarget}}
    @attachment={{this.attachment}}
    @targetAttachment={{this.targetAttachment}}
    @targetModifier={{this.targetModifier}}
    @classPrefix={{this.tetherClassPrefix}}
    @offset={{this.offset}}
    @targetOffset={{this.targetOffset}}
    @constraints={{this.constraints}}
    ...attributes
  >
    {{yield}}
  </EmberTether>
  */
  {
    "id": "4bvC9QU+",
    "block": "[[[41,[30,0,[\"hasOverlay\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@to\"],[[30,0,[\"destinationElementId\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],[[16,0,[30,0,[\"overlayClassNamesString\"]]]],[[\"@onClickOverlay\"],[[30,0,[\"onClickOverlay\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null],[8,[39,3],[[16,0,[30,0,[\"containerClassNamesString\"]]],[17,1]],[[\"@target\",\"@attachment\",\"@targetAttachment\",\"@targetModifier\",\"@classPrefix\",\"@offset\",\"@targetOffset\",\"@constraints\"],[[30,0,[\"tetherTarget\"]],[30,0,[\"attachment\"]],[30,0,[\"targetAttachment\"]],[30,0,[\"targetModifier\"]],[30,0,[\"tetherClassPrefix\"]],[30,0,[\"offset\"]],[30,0,[\"targetOffset\"]],[30,0,[\"constraints\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[18,2,null],[1,\"\\n\"]],[]]]]]],[\"&attrs\",\"&default\"],false,[\"if\",\"ember-wormhole\",\"ember-modal-dialog/overlay\",\"ember-tether\",\"yield\"]]",
    "moduleName": "ember-modal-dialog/templates/components/tether-dialog.hbs",
    "isStrictMode": false
  });
});