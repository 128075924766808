define("ember-modal-dialog/components/positioned-container", ["exports", "@ember/debug", "@ember/utils", "@ember/component", "@ember/string", "@ember/object", "@ember/object/evented"], function (_exports, _debug, _utils, _component, _string, _object, _evented) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SUPPORTED_TARGET_ATTACHMENTS = ['top', 'right', 'bottom', 'left', 'center', 'elementCenter', 'none'];
  var _default = _exports.default = _component.default.extend({
    // target - element selector, element, or Ember View
    // targetAttachment - top, right, bottom, left, center, or none
    //   left, right, top, bottom (relative to target)
    //   center (relative to container)
    targetAttachment: 'center',
    isPositioned: (0, _object.computed)('targetAttachment', 'target', 'renderInPlace', function () {
      if (this.renderInPlace) {
        return false;
      }
      let target = this.target;
      let targetAttachment = this.targetAttachment;
      if (target === 'body' && (targetAttachment === 'center' || targetAttachment === 'middle center')) {
        return false;
      }
      if (target && targetAttachment) {
        return true;
      }
      return false;
    }),
    didGetPositioned: (0, _object.observer)('isPositioned', (0, _evented.on)('didInsertElement', function () {
      if (this._state !== 'inDOM') {
        return;
      }
      if (this.isPositioned) {
        this.updateTargetAttachment();
      } else {
        this.element.style.left = '';
        this.element.style.top = '';
      }
    })),
    getWrappedTargetAttachmentElement() {
      const target = this.target;
      if (!target) {
        return null;
      }
      if ((0, _utils.typeOf)(target) === 'string') {
        const targetSelector = target;
        const wrappedElement = document.querySelector(targetSelector);
        (false && !(wrappedElement) && (0, _debug.assert)(`No element found for modal-dialog's target selector '${targetSelector}'.`, wrappedElement));
        return wrappedElement;
      }

      // passed an ember view or component
      if (target.element) {
        return target.element;
      }

      // passed an element directly
      return target;
    },
    updateTargetAttachment() {
      let targetAttachment = this.targetAttachment;
      // Convert tether-styled values like 'middle right' to 'right'
      targetAttachment = targetAttachment.split(' ').slice(-1)[0];
      (false && !(SUPPORTED_TARGET_ATTACHMENTS.indexOf(targetAttachment) > -1) && (0, _debug.assert)(`Positioned container supports targetAttachments of ${SUPPORTED_TARGET_ATTACHMENTS.join(', ')}`, SUPPORTED_TARGET_ATTACHMENTS.indexOf(targetAttachment) > -1));
      const targetAttachmentMethod = `align${(0, _string.capitalize)(targetAttachment)}`;
      const targetAttachmentElement = this.getWrappedTargetAttachmentElement();
      this[targetAttachmentMethod](targetAttachmentElement);
    },
    alignCenter() {
      const elementWidth = this.element.offsetWidth;
      const elementHeight = this.element.offsetHeight;
      this.element.style.left = '50%';
      this.element.style.top = '50%';
      this.element.style.marginLeft = `${elementWidth * -0.5}px`;
      this.element.style.marginTop = `${elementHeight * -0.5}px`;
    },
    alignLeft(targetAttachmentElement) {
      (false && !(targetAttachmentElement) && (0, _debug.assert)('Left targetAttachment requires a target', targetAttachmentElement));
      const elementWidth = this.element.offsetWidth;
      const originOffset = targetAttachmentElement.getBoundingClientRect();
      const originOffsetTop = originOffset.top;
      this.element.style.left = `${originOffset.left - elementWidth}px`;
      this.element.style.top = `${originOffsetTop}px`;
    },
    alignRight(targetAttachmentElement) {
      (false && !(targetAttachmentElement) && (0, _debug.assert)('Right targetAttachment requires a target', targetAttachmentElement));
      const targetWidth = targetAttachmentElement.offsetWidth;
      const originOffset = targetAttachmentElement.getBoundingClientRect();
      const originOffsetTop = originOffset.top;
      this.element.style.left = `${originOffset.left + targetWidth}px`;
      this.element.style.top = `${originOffsetTop}px`;
    },
    alignTop(targetAttachmentElement) {
      (false && !(targetAttachmentElement) && (0, _debug.assert)('Top targetAttachment requires a target', targetAttachmentElement));
      const elementWidth = this.element.offsetWidth;
      const elementHeight = this.element.offsetHeight;
      const originOffset = targetAttachmentElement.getBoundingClientRect();
      const originOffsetTop = originOffset.top;
      const targetWidth = targetAttachmentElement.offsetWidth;
      this.element.style.left = `${originOffset.left + targetWidth / 2 - elementWidth / 2}px`;
      this.element.style.top = `${originOffsetTop - elementHeight}px`;
    },
    alignBottom(targetAttachmentElement) {
      (false && !(targetAttachmentElement) && (0, _debug.assert)('Bottom targetAttachment requires a target', targetAttachmentElement));
      const elementWidth = this.element.offsetWidth;
      const originOffset = targetAttachmentElement.getBoundingClientRect();
      const originOffsetTop = originOffset.top;
      const targetWidth = targetAttachmentElement.offsetWidth;
      const targetHeight = targetAttachmentElement.offsetHeight;
      this.element.style.left = `${originOffset.left + targetWidth / 2 - elementWidth / 2}px`;
      this.element.style.top = `${originOffsetTop + targetHeight}px`;
    },
    alignElementCenter(targetAttachmentElement) {
      (false && !(targetAttachmentElement) && (0, _debug.assert)('ElementCenter targetAttachment requires a target', targetAttachmentElement));
      const elementWidth = this.element.offsetWidth;
      const originOffset = targetAttachmentElement.getBoundingClientRect();
      const originOffsetTop = originOffset.top;
      const targetWidth = targetAttachmentElement.offsetWidth;
      const targetHeight = targetAttachmentElement.offsetHeight;
      const elementHeight = this.element.offsetHeight;
      this.element.style.left = `${originOffset.left + targetWidth / 2 - elementWidth / 2}px`;
      this.element.style.top = `${originOffsetTop + targetHeight / 2 - elementHeight / 2}px`;
    },
    alignNone() {}
  });
});