define("ember-rollbar-client/services/rollbar", ["exports", "@ember/application", "@ember/object", "@ember/service", "lodash.merge", "rollbar"], function (_exports, _application, _object, _service, _lodash, _rollbar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _service.default.extend({
    enabled: (0, _object.computed)({
      get() {
        return this.get('config.enabled');
      },
      set(key, value) {
        this.get('notifier').configure({
          enabled: value
        });
        return value;
      }
    }),
    currentUser: (0, _object.computed)({
      set(key, value) {
        this.get('notifier').configure({
          payload: {
            person: value
          }
        });
        return value;
      }
    }),
    notifier: (0, _object.computed)(function () {
      return this.rollbarClient();
    }).readOnly(),
    config: (0, _object.computed)(function () {
      return (0, _application.getOwner)(this).resolveRegistration('config:environment').emberRollbarClient;
    }).readOnly(),
    rollbarClient(customConfig = {}) {
      let config = (0, _lodash.default)({}, this.get('config'), customConfig);
      return new _rollbar.default(config);
    },
    // Notifications

    critical(...args) {
      return this.get('notifier').critical(...args);
    },
    error(...args) {
      return this.get('notifier').error(...args);
    },
    warning(...args) {
      return this.get('notifier').warning(...args);
    },
    info(...args) {
      return this.get('notifier').info(...args);
    },
    debug(...args) {
      return this.get('notifier').debug(...args);
    }
  });
});