define("ember-table/components/ember-tfoot/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each this.wrappedRowArray as |rowValue|}}
    <EmberTablePrivate::RowWrapper
      @rowValue={{rowValue}}
      @columns={{this.columns}}
      @columnMetaCache={{this.columnMetaCache}}
      @rowMetaCache={{this.rowMetaCache}}
      @canSelect={{this.canSelect}}
      @rowSelectionMode={{this.rowSelectionMode}}
      @checkboxSelectionMode={{this.checkboxSelectionMode}}
      @rowsCount={{this.wrappedRowArray.length}} as |api|
    >
      {{#if (has-block)}}
        {{yield (hash
          rowValue=api.rowValue
          rowMeta=api.rowMeta
          cells=api.cells
          rowSelectionMode=api.rowSelectionMode
          rowsCount=api.rowsCount
  
          row=(component "ember-tr" api=api)
        )}}
      {{else}}
        <EmberTr @api={{api}} />
      {{/if}}
    </EmberTablePrivate::RowWrapper>
  {{/each}}
  
  */
  {
    "id": "/IEu90BH",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"wrappedRowArray\"]]],null]],null],null,[[[1,\"  \"],[8,[39,2],null,[[\"@rowValue\",\"@columns\",\"@columnMetaCache\",\"@rowMetaCache\",\"@canSelect\",\"@rowSelectionMode\",\"@checkboxSelectionMode\",\"@rowsCount\"],[[30,1],[30,0,[\"columns\"]],[30,0,[\"columnMetaCache\"]],[30,0,[\"rowMetaCache\"]],[30,0,[\"canSelect\"]],[30,0,[\"rowSelectionMode\"]],[30,0,[\"checkboxSelectionMode\"]],[30,0,[\"wrappedRowArray\",\"length\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[48,[30,3]],[[[1,\"      \"],[18,3,[[28,[37,6],null,[[\"rowValue\",\"rowMeta\",\"cells\",\"rowSelectionMode\",\"rowsCount\",\"row\"],[[30,2,[\"rowValue\"]],[30,2,[\"rowMeta\"]],[30,2,[\"cells\"]],[30,2,[\"rowSelectionMode\"]],[30,2,[\"rowsCount\"]],[50,\"ember-tr\",0,null,[[\"api\"],[[30,2]]]]]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,8],null,[[\"@api\"],[[30,2]]],null],[1,\"\\n\"]],[]]],[1,\"  \"]],[2]]]]],[1,\"\\n\"]],[1]],null]],[\"rowValue\",\"api\",\"&default\"],false,[\"each\",\"-track-array\",\"ember-table-private/row-wrapper\",\"if\",\"has-block\",\"yield\",\"hash\",\"component\",\"ember-tr\"]]",
    "moduleName": "ember-table/components/ember-tfoot/template.hbs",
    "isStrictMode": false
  });
});